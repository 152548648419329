import PropTypes from "prop-types"
import React from "react"

const PostExcerpt = ({ excerpt }) => (
  <p className="text-xl mb-6 text-gray-700 ">{excerpt}</p>
)

PostExcerpt.propTypes = {
  post: PropTypes.string,
}

PostExcerpt.defaultProps = {
  post: ``,
}

export default PostExcerpt
