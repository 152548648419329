import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import PostTitle from "../components/post-title"
import PostExcerpt from "../components/post-excerpt"
import PostMeta from "../components/post-meta"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Matt Langeman" />
    <div className={`flex flex-col items-center w-full`}>
      {data.allMarkdownRemark.edges.map(({ node }, index) => (
        <Link key={node.fields.slug} to={`/${node.fields.slug}`} className={`w-full md:5/6 lg:w-5/6 xl:w-7/12`}>
        <article key={index}>
          <div className={`flex flex-col bg-white shadow px-6 py-10 md:p-10 mb-2 md:mb-6`}>
            <PostMeta category={node.frontmatter.category} date={node.frontmatter.date}/>
            <PostTitle title={node.frontmatter.title}/>
            <PostExcerpt excerpt={node.frontmatter.excerpt}/>
            <div className={`w-full text-gray-700 content-center font-semibold`}>Read More</div>
          </div>
        </article>
    </Link>
      ))}
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query AllPosts {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/src/posts/.*.md$/" }
        frontmatter: { published: { eq: true }, date: { lte: "NOW" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM D YYYY")
            excerpt
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
