import PropTypes from "prop-types"
import React from "react"

const PostTitle = ({ title }) => (
  <h1 className={`text-2xl font-bold mb-2 leading-tight`}>{title}</h1>
)

PostTitle.propTypes = {
  post: PropTypes.string,
}

PostTitle.defaultProps = {
  post: ``,
}

export default PostTitle
