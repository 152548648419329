import PropTypes from "prop-types"
import React from "react"

const PostMeta = ({ category, date }) => (
  <div className={`flex flex-row justify-between w-full text-white shadow text-xs p-2 mb-6 bg-${category}`}>
    <div className={`uppercase`}>{category}</div>
    <div>{date}</div>
  </div>
)

PostMeta.propTypes = {
  category: PropTypes.string,
  date: PropTypes.string,
}

PostMeta.defaultProps = {
  category: ``,
  date: ``,
}

export default PostMeta
